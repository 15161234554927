<template>
  <div class="home">
    <v-layout row wrap>
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          Dashboard / Accounts / {{ account.name }}
        </h1>
      </v-flex>
      <v-flex xs12 md12 lg8>
        <v-simple-table>
          <tbody>
            <tr>
              <th class="fs-12 primary--text">Name</th>
              <th class="fs-12">{{ account.name }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Email</th>
              <th class="fs-12">{{ account.email }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Phone</th>
              <th class="fs-12">{{ account.phone }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Role</th>
              <th class="fs-12">{{ account.role }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Options</th>
              <th class="fs-12">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" class="black--text" light v-bind="attrs" v-on="on">
                      Manage Account <span><v-icon>mdi-menu-down</v-icon></span>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>Edit Account</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Reset Password</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Delete Account</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </th>
            </tr>
          </tbody>
        </v-simple-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { BASE_URL } from "@/config";
import Axios from "axios";

export default {
  data() {
    return {
      account: [],
    };
  },
  mounted() {
    this.fetchAccount();
  },
  methods: {
    async fetchAccount() {
      let url = BASE_URL + "/admin/" + this.$route.params.id;
      let { data } = await Axios.get(url);
      this.account = data;
    },
    edit() {
      this.$router.push({
        name: "EditAccount",
        params: { id: this.$route.params.id },
      });
    },
  },
};
</script>

  <style scoped>
.fs-12 {
  font-size: 12pt !important;
  font-weight: 400 !important;
}
</style>